import { render, staticRenderFns } from "./AgeGate.vue?vue&type=template&id=410b45f6&scoped=true&"
var script = {}
import style0 from "./AgeGate.vue?vue&type=style&index=0&id=410b45f6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "410b45f6",
  null
  
)

export default component.exports