<template>
    <div class="overlay" v-if="ageGate">
      <img src="/Logo.png" alt="Logo Ursus Cooler" class="logo">
      <h2 style="font-family: Futura, Trebuchet MS, Arial, sans-serif;">Te rugăm să introduci anul nașterii</h2>
      <input type="text" placeholder="AAAA" v-model="age" class="asds" style="color:#05B9E3 !important;">
      <div class="options">
        <span class="button" @click="confirmAge()">CONFIRMĂ</span>
      </div>
      <h2 style="font-family: Futura, Trebuchet MS, Arial, sans-serif;">Trebuie să ai peste 18 ani pentru a accesa site-ul</h2>
    </div>
</template>

<script>
export default {
  data () {
    return {
      ageGate: true,
      age: null
    }
  },
  methods: {
    confirmAge () {
      console.log(this.age)
      if (this.age <= 2004) {
        localStorage.setItem('ageGate', true)
        this.ageGate = false
      }
    }
  },
  mounted () {
    if (localStorage.getItem('ageGate') === 'true') {
      this.ageGate = false
    }
  }
}
</script>

<style lang="sass" scoped>
.overlay
  position: fixed
  min-height: 100vh
  width: 100vw
  background-color: #05B9E3!important
  color: #fff
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  box-sizing: border-box
  z-index: 100
  padding: 5vh 5vw
  text-align: center

  img
    max-width: 150px

  h1
    margin: 0
    margin-bottom: 4vh
    text-align: center
  

  .options
    display: flex
    justify-content: space-around

  .asds
    width: clamp(250px, 80vw, 300px) !important
    text-align: center
    font-size: 20px
    color:#05B9E3 !important
    font-family: Futura, Trebuchet MS, Arial, sans-serif

.button
  padding: 15px 25px
  font-family: Futura, Trebuchet MS, Arial
  background-color: #fff
  color: #05B9E3
  font-weight: 800
  display: inline-block
  text-decoration: none
  border-radius: 7px
  transition: all 0.2s ease-in-out
  cursor: pointer
  margin: 0 5vw
  &:active, &:hover
    background-color: #05B9E3
    color: #fff
    border: 1px solid #fff
  
@media (max-width: 1000px)
  .overlay
    padding: 0 !important
</style>
