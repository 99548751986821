<template>
    <div class="main-banner">
        <div class="header">
            <div class="logo">
                <router-link to="/"><img src="/logo_new.svg" alt="Logo campanie"></router-link>
            </div>
            <div class="nav">
                <!-- <router-link to="/" href="#" class="texts" v-scroll-to="'#imagine'">Acasă</router-link>
                <router-link to="/cora" href="#" class="texts desktop" v-scroll-to="'#imagine'">Detalii campanie</router-link>
                <a href="/Regulament.pdf" class="texts desktop" target="_blank">Regulament</a> -->
                <!--<a href="#" class="button"  v-scroll-to="'#formular'">Participă</a>-->
            </div>
        </div>
        <div class="push"></div>

        <div class="imagine" id="imagine">
            <img src="/TT-1355x1920_fara-disclaimer_.webp" class="mobile" alt="Key visual">
            <img src="/TT-1820x800_2.webp" class="desktop" alt="Key visual">
            <!--<router-link to="/" class="homebutton desktop"><img src="/logo.svg" alt="Logo campanie"></router-link>-->
        <p class="disclaimer" style="background-color:#05b9e3; padding:15px 15px; color:white; font-size:11px; margin-top:-10px;">*În limita stocului de premii disponibile. Durata promoției: 23.10.2023 – 16.12.2023. Consultă gratuit regulamentul promoției pe <a href="/Regulament_Cooler_TT_final.pdf" target="_blank" style="color:white;">https://berefaraalcool.ro</a> și pe <a href="https://ursuscooler.ro/regulamente/" style="color:white;">https://ursuscooler.ro/regulamente/</a>. Numărul total de premii este 64.330, cu o valoare totală netă comercială estimativă de 246.815,1 LEI (cu TVA inclus). Premiile: mini frigidere USB, căștile JBL Tune 500 și boxele Google Home Mini se acordă prin tragere la sorți. Lista magazinelor participante poate fi consultată în regulamentul campaniei. Imaginile premiilor sunt cu titlul de prezentare.<br></p>
        </div>
        

        <div style="text-align: center;"><h1 style="color: #05b9e3;">Campania "Cumpără Cooler și poți câștiga premii pentru pauza ta!" s-a încheiat!</h1>
            <!-- <div style="text-align: center;"><h1 style="color: #05b9e3;">Câștigătorii vor fi afșați după validare.</h1> -->
        <span class="button" style="font-family: Futura, Trebuchet MS, Arial, sans-serif; font-size:20px; text-align: center;"> <a style= "color:#05B9E3;" href="/Lista_castigatori_Cooler_TT.pdf" target="_blank">VEZI CÂȘTIGĂTORII</a></span></div><br>


        <!-- <div class="formular" id="formular"> -->
        <!--<h1>Participă</h1>-->
        <!-- <div class="chestii">
            <div class="chestiicontain">
                <div>
            <input type="text" name="name" id="name" placeholder="Nume*" v-model.trim="name">
            <div v-if="$v.name.$dirty">
                <div v-if="!$v.name.required" class="error">
                *Câmpul este obligatoriu
                </div>
            </div>
            </div>
            <div>
            <input type="text" name="prenume" id="prenume" placeholder="Prenume*" v-model.trim="prenume">
            <div v-if="$v.prenume.$dirty">
                <div v-if="!$v.prenume.required" class="error">
                *Câmpul este obligatoriu
                </div> -->
                <!-- Add other validation rules for the "prenume" field if needed -->
            <!-- </div>
            </div>
            </div> -->


            <!-- <div class="chestiicontain" style="font-family: Futura, Trebuchet MS, Arial, sans-serif;">
                <div>
                    <input type="text" name="numar_telefon" id="telefon" placeholder="Număr de telefon mobil*" v-model.trim="$v.phoneNumber.$model">
                    <div v-if="$v.phoneNumber.$dirty">
                    <div v-if="!$v.phoneNumber.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    <div v-if="!$v.phoneNumber.numeric || !$v.phoneNumber.minLength || !$v.phoneNumber.maxLength" class="error">
                        Numărul de telefon trebuie să fie valid și să înceapă cu 0.
                    </div>
                    </div>
                </div>
                <div>
                    <datepicker :format="'yyyy-MM-dd'" :input-class="'asds'" :monday-first="true" placeholder="Data nașterii*" v-model.trim="$v.birthDate.$model" :open-date="new Date(2005, 3, 1)"/>
                    <div v-if="$v.birthDate.$dirty">
                    <div v-if="!$v.birthDate.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    </div>
                </div>
            </div>
            <div class="chestiicontain">
                <div>
                    <input type="text" placeholder="Număr bon fiscal*" v-model.trim="$v.identifyer.$model">
                    <div v-if="$v.identifyer.$dirty">
                    <div v-if="!$v.identifyer.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    <div v-if="!$v.identifyer.numeric || !$v.identifyer.minLength || !$v.identifyer.maxLength" class="error">
                        *Numărul bonului fiscal trebuie să conțină între 1 și 10 cifre. Sună-ne la 0312244814 (tarif normal, Luni – Vineri 10:00 – 18:00) pentru asistență!
                    </div>



                    </div>
                </div>
                <div>
                    <datepicker :format="'yyyy-MM-dd'" :input-class="'asds'" :monday-first="true" placeholder="Dată bon fiscal*" v-model.trim="$v.dataDeAzi.$model"/>
                    <div v-if="$v.dataDeAzi.$dirty">
                    <div v-if="!$v.dataDeAzi.required" class="error-checkmark">
                        *Câmpul este obligatoriu
                    </div>
                    </div>
                </div>
            </div>
<div style="margin-left:15px;">
    <label for="imageFile" class="file-label">Încarcă poza bonului fiscal</label><br>
    <input type="file" name="imageFile" id="imageFile" @change="handleImageUpload" accept="image/*">
    <div v-if="$v.imageFile.$dirty">
        <div v-if="!$v.imageFile.required" class="error" style="color:red; font-size:15px; font-weight:700; margin-bottom:1vh;">
            *Câmpul este obligatoriu
            </div>
                </div>
    </div>


            <div class="chestiicontain">
                <label class="containerl lol" style="font-family: Futura, Trebuchet MS, Arial, sans-serif;">Am citit, înțeles și sunt de acord cu <a href="/Regulament_Cooler_TT_final.pdf" style="display: inline; color: #05B9E3;" target="_blank">Regulamentul Oficial al Campaniei.</a>
                    <input type="checkbox" value="1" v-model.trim="$v.acordTC.$model">
                    <span class="checkmark"></span>
                </label>
                <div v-if="$v.acordTC.$dirty">
                    <div v-if="!$v.acordTC.required" class="error">
                    *Câmpul este obligatoriu
                    </div>
                </div>
            </div>
        </div>

        <br><br>
        <span class="button" style="font-family: Futura, Trebuchet MS, Arial, sans-serif; font-size:20px;" @click="submitEntry()">Participă</span>
        </div> -->

        <div class="footer" style="font-family: Futura, Trebuchet MS, Arial, sans-serif;">
            <router-link to="termeni-si-conditii" tag="a" >Termeni și condiții</router-link>
            <a href="Politica-confidentialitate&cookies.pdf" target="_blank">Politica privind protecția datelor cu caracter personal</a>
            <router-link to="politica-de-cookie-uri" tag="a">Politica de cookie-uri</router-link>
            <a href="/Regulament_Cooler_TT_final.pdf" target="_blank">  Regulamentul Oficial al Campaniei </a>
        </div>

         <sweet-modal ref="bine" icon="success" @close="$router.go()">
            {{messagebun}}
        </sweet-modal>
        <sweet-modal ref="rau" icon="error">
            {{message}}
        </sweet-modal>

    </div>
</template>

<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'
// import datepicker from 'vuejs-datepicker'

export default {
  components: {
    // datepicker
  },
  data () {
    return {
      name: '',
      prenume: '',
      imageFile: null,
      phoneNumber: '',
      identifyer: '',
      dataDeAzi: '',
      acordTC: '',
      acordGDPR: '',
      birthDate: '',
      message: '',
      messagebun: ''
    }
  },
  methods: {
    handleImageUpload(event) {
    this.imageFile = event.target.files[0];
  },
    async submitEntry () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
const dateDeAzi = new Date(this.dataDeAzi);
          const today = new Date();
          const year = dateDeAzi.getFullYear();
          const month = dateDeAzi.getMonth() + 1;
          const day = dateDeAzi.getDate();
          
          // Disable data bon
          const oct23 = new Date(year, 9, 23);
         if (dateDeAzi < oct23 || dateDeAzi > today) {
            // Block dates before July 1st and after the current date
            this.success = false;
            this.message = 'Salut! Campania Ursus Cooler, desfășurată în magazinele de proximitate, a început pe 23 Octombrie 2023, ora 00:00:00. Te rugăm să completezi data corectă de pe bonul fiscal.';
            this.$refs.rau.open();
            return; // Exit the method if the date is blocked
          }

          // const dataTosubmit = {
          //   name: this.name,
          //   prenume: this.prenume,
          //   phoneNumber: this.phoneNumber,
          //   identifyer: this.identifyer.toUpperCase(),
          //   receiptDate: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,
          //   birthDate: `${new Date(this.birthDate).getFullYear()}-${new Date(this.birthDate).getMonth() + 1 < 10 ? '0' + (new Date(this.birthDate).getMonth() + 1) : (new Date(this.birthDate).getMonth() + 1)}-${new Date(this.birthDate).getDate() < 10 ? '0' + new Date(this.birthDate).getDate() : new Date(this.birthDate).getDate()}`,
          //   imageFile: this.imageFile,
          //   acordTC: this.acordTC,
          //   retailer: 'proximitate'
          // }

          const formData = new FormData();

          formData.append("name", this.name);
          formData.append("prenume", this.prenume);
          formData.append("phoneNumber", this.phoneNumber);
          formData.append("identifyer", this.identifyer.toUpperCase());
          formData.append("receiptDate", `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`);
          formData.append("birthDate", `${new Date(this.birthDate).getFullYear()}-${new Date(this.birthDate).getMonth() + 1 < 10 ? '0' + (new Date(this.birthDate).getMonth() + 1) : (new Date(this.birthDate).getMonth() + 1)}-${new Date(this.birthDate).getDate() < 10 ? '0' + new Date(this.birthDate).getDate() : new Date(this.birthDate).getDate()}`);
          formData.append("imageFile", this.imageFile);
          formData.append("acordTC", this.acordTC);
          formData.append("retailer", 'proximitate');

          const config = {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }

          axios.post('/submitEntryOff.php', formData, config)
            .then ((raspuns) => {
                // console.log(raspuns.data);
                this.messagebun = raspuns.data.message
                // alert(raspuns.data.message)
                this.$refs.bine.open();
            })
            .catch((error) => {
                if (error.response.status == 422) {
                    this.serverErrors = error.response.data.errors;
                }
            })
        } catch (err) {
        //   alert(err.response.data.message)
            this.message = err.response.data.message
            this.$refs.rau.open();
        }
      } else {
        this.success = false
        this.message = 'Completează cu atenție toate câmpurile din formular!'
        this.$refs.rau.open();
      }
    }
  },
  
  validations: {
      name: {
    required,
  },
  prenume: {
    required,
  },
  imageFile: {
    required
    },
    phoneNumber: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
      startsWithZero(value) {
      if (value.charAt(0) !== '0') {
        return {
          valid: false,
          message: 'Numărul de telefon trebuie să înceapă cu "0".'
        };
      }
      return { valid: true };
    }
    },
    identifyer: {
      required,
      numeric,
      minLength: minLength(1),
      maxLength: maxLength(10)

    },
    dataDeAzi: {
      required
    },
    birthDate: {
      required
    },
    acordTC: {
      required
    }
  }
}
</script>


<style lang="sass">

.main-banner
    width: 100vw
    .imagine
        width: 100vw
        img
            width: 100%

    .formular
        background-color: var(--main-color)
        margin-top: -1%
        display: flex!important
        box-sizing: border-box
        flex-direction: column
        align-items: center
        justify-content: center
        padding: 5vh 20vw

        h1
            margin-top: 0
            color: var(--secondary-color)

        .chestii
            max-width: clamp(750px, 50vw, 900px)


        .chestiicontain
            display: flex
            justify-content: space-between

            input
                background-color: #fff
                border: 2px solid var(--secondary-color)
                width: 300px
                padding: 15px 30px
                margin: 10px 10px
                font-size: 16px

                .button
                    margin-top: 4vh
            .error
                color: red
                font-size: 15px
                margin-bottom: 1vh
                text-align: center
                font-weight: bold
            .error-checkmark
                color: red
                font-size: 15px
                margin-bottom: 1vh
                margin-top: 0%
                text-align: center
                font-weight: bold

.footer
    background-color: var(--secondary-color)
    display: flex
    height: 8.5vh
    padding: 2vh 10vw
    box-sizing: border-box
    align-items: center
    justify-content: space-between  
    text-align: center
    a
        color: #fff
        text-decoration: none
        cursor: pointer
        font-size: clamp(15px, 0.1vw, 72px)
        transition: all 0.3s ease-in-out

        &:hover
            color: var(--main-color)
            text-decoration: underline
        &:active
            color: var(--main-color)
            text-decoration: underline


            /* Customize the label (the container) */
.containerl
    display: block
    position: relative
    padding-left: 35px
    margin-bottom: 12px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    margin-top: 3vh
    font-weight: 400
    text-align: left
    align-self: flex-start
    color: var(--secondary-color)

/* Hide the browser's default checkbox */
.containerl input[type="checkbox"]
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

/* Create a custom checkbox */
.checkmark
    position: absolute
    left: 0
    height: 18px
    width: 18px
    background-color: var(--main-color)
    border: solid 1px var(--secondary-color)
    border-radius: 5px

/* On mouse-over, add a grey background color */
.containerl:hover input ~ .checkmark
    background-color: rgba(0, 0, 0, 0)
    border: solid 1px var(--secondary-color)

/* When the checkbox is checked, add a blue background */
.containerl input:checked ~ .checkmark
    background-color: rgba(0, 0, 0, 0)
    border: solid 2px var(--secondary-color)

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after
    content: ""
    position: absolute

/* Show the checkmark when checked */
.containerl input:checked ~ .checkmark:after
  display: block

/* Style the checkmark/indicator */
.containerl .checkmark:after
    left: 6px
    top: 2px
    width: 5px
    height: 10px
    border: solid var(--secondary-color)
    border-width: 0 3px 3px 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)

@media (max-width: 900px)
    .main-banner
        .header
            padding: 2vh 2vw
            height: 8vh
            .logo
                width: 30%
                img
                    width: 40%
            .nav
                width: clamp(100px, 40vw, 200px)
                .texts
                    font-weight: 700

                .button
                    font-weight: 700
                    padding: 10px 15px
        .push
            height: 8vh
        .formular
            padding: 5vh 5vw
            flex-direction: column

            .checkmark
                top: 35%

            .chestii
                width: 90vw

            .chestiicontain
                flex-direction: column
                align-items: center

                input
                    width: 250px
                
                .asds
                    width: 310px !important

        .footer
            flex-direction: column
            height: auto
            align-items: center
            justify-content: space-between

        .file-label
            font-size: 16px
            margin-right: 10px

</style>
