<template>
    <div class="main-banner">
        <div class="header">
            <div class="logo">
                <router-link to="/"><img src="/logo_new.svg" alt="Logo campanie"></router-link>
            </div>
            <div class="nav">
                <!-- <router-link to="/" href="#" class="texts" v-scroll-to="'#imagine'">Acasă</router-link>
                <router-link to="/cora" href="#" class="texts desktop" v-scroll-to="'#imagine'">Detalii campanie</router-link> -->
                <!--<a href="/Regulament-penny.pdf" class="texts desktop" target="_blank">Regulament</a>
                <a href="/Lista finala castigatori Cooler - Refresh fara alcool - Penny.pdf" target="_blank" class="button">Câștigători</a>-->
            </div>
        </div>
        <div class="push"></div>

        <div class="imagine" id="imagine">
            <!-- <img src="/Penny-Mobile.webp" class="mobile" alt="Key visual">
            <img src="/Penny_1820x800.png" class="desktop" alt="Key visual">-->
            <!-- <router-link to="/" class="homebutton desktop"><img src="/logo.svg" alt="Logo campanie"></router-link> -->
        </div>
&nbsp;&nbsp;&nbsp;
        <div class="formular" id="formular">
            <h3 style="font-family: Futura, Trebuchet MS, Arial, sans-serif; color: #05B9E3;">Vă rugăm să introduceți numărul de telefon și numărul bonului fiscal pentru verificare.</h3>
        <!--<h1>Campania s-a încheiat! Lista câștigătorilor este disponibilă <a href="/Lista finala castigatori Cooler - Refresh fara alcool - Penny.pdf" target="_blank">aici</a>!</h1>-->
         <div class="chestii">
            <div class="chestiicontain">
                <div>
                    <input type="text" name="numar_telefon" id="telefon" placeholder="Număr de telefon mobil*" v-model.trim="$v.phoneNumber.$model">
                    <div v-if="$v.phoneNumber.$dirty">
                    <div v-if="!$v.phoneNumber.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    <div v-if="!$v.phoneNumber.numeric || !$v.phoneNumber.minLength || !$v.phoneNumber.maxLength" class="error">
                        Numărul de telefon trebuie să fie valid
                    </div>
                    </div>
                </div>
                <div>
                    <datepicker :format="'yyyy-MM-dd'" :input-class="'asds'" :monday-first="true" placeholder="Data nașterii*" v-model.trim="$v.birthDate.$model"/>
                    <div v-if="$v.birthDate.$dirty">
                    <div v-if="!$v.birthDate.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    </div>
                </div>
            </div>
            <div class="chestiicontain">
                <div>
                    <input type="text" placeholder="Număr bon fiscal*" v-model.trim="$v.identifyer.$model">
                    <div v-if="$v.identifyer.$dirty">
                    <div v-if="!$v.identifyer.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    </div>
                </div>
                <div>
                    <datepicker :format="'yyyy-MM-dd'" :input-class="'asds'" :monday-first="true" placeholder="Dată bon fiscal*" v-model.trim="$v.dataDeAzi.$model"/>
                    <div v-if="$v.dataDeAzi.$dirty">
                    <div v-if="!$v.dataDeAzi.required" class="error">
                        *Câmpul este obligatoriu
                    </div>
                    </div>
                </div>
            </div>
            <div class="chestiicontain">
                <label class="containerl lol">Am citit, înțeles și sunt de acord cu <a href="/Regulament-penny.pdf" style="display: inline; color: var(--accent-color)" target="_blank">Regulamentul Oficial al Campaniei.</a>
                    <input type="checkbox" value="1" v-model.trim="$v.acordTC.$model">
                    <span class="checkmark"></span>
                </label>
                <div v-if="$v.acordTC.$dirty">
                    <div v-if="!$v.acordTC.required" class="error">
                    *Câmpul este obligatoriu
                    </div>
                </div>
            </div>
        </div>

        <br><br>
        <span class="button" @click="submitEntry()">Participă</span> 
        </div>

        <div class="footer" style="font-family: Futura, Trebuchet MS, Arial, sans-serif;">
            <router-link to="termeni-si-conditii" tag="a" >Termeni și condiții</router-link>
            <a href="Politica-confidentialitate&cookies.pdf" target="_blank">Politica privind protecția datelor cu caracter personal</a>
            <router-link to="politica-de-cookie-uri" tag="a">Politica de cookie-uri</router-link>
            <a href="/Regulament-penny.pdf" target="_blank">  Regulamentul Oficial al Campaniei </a>
        </div>
        <sweet-modal ref="bine" icon="success" @close="$router.go()">
            {{messagebun}}
        </sweet-modal>
        <sweet-modal ref="rau" icon="error">
            {{message}}
        </sweet-modal>
    </div>
</template>

<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'
// import datepicker from 'vuejs-datepicker'

export default {
  components: {
    // datepicker
  },
  data () {
    return {
      phoneNumber: '',
      identifyer: '',
      dataDeAzi: '',
      acordTC: '',
      acordGDPR: '',
      birthDate: '',
      message: '',
      messagebun: ''
    }
  },
  methods: {
    async submitEntry () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const dataTosubmit = {
            phoneNumber: this.phoneNumber,
            identifyer: this.identifyer.toUpperCase(),
            receiptDate: `${new Date(this.dataDeAzi).getFullYear()}-${new Date(this.dataDeAzi).getMonth() + 1 < 10 ? '0' + (new Date(this.dataDeAzi).getMonth() + 1) : (new Date(this.dataDeAzi).getMonth() + 1)}-${new Date(this.dataDeAzi).getDate() < 10 ? '0' + new Date(this.dataDeAzi).getDate() : new Date(this.dataDeAzi).getDate()}`,
            birthDate: `${new Date(this.birthDate).getFullYear()}-${new Date(this.birthDate).getMonth() + 1 < 10 ? '0' + (new Date(this.birthDate).getMonth() + 1) : (new Date(this.birthDate).getMonth() + 1)}-${new Date(this.birthDate).getDate() < 10 ? '0' + new Date(this.birthDate).getDate() : new Date(this.birthDate).getDate()}`,
            acordTC: this.acordTC,
            retailer: 'penny'
          }
          const raspuns = await axios.post('/api/v1/submitEntry', dataTosubmit)
          this.messagebun = raspuns.data.message
        //   alert(raspuns.data.message)
          this.$refs.bine.open();
        } catch (err) {
        //   alert(err.response.data.message)
            this.message = err.response.data.message
            this.$refs.rau.open();
        }
      } else {
        this.success = false
        this.message = 'Toate campurile sunt obligatorii'
        this.$refs.rau.open();
      }
    }
  },
  validations: {
    phoneNumber: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    identifyer: {
      required
    },
    dataDeAzi: {
      required
    },
    birthDate: {
      required
    },
    acordTC: {
      required
    }
  }
}
</script>


<style lang="sass">

.main-banner
    width: 100vw
    .imagine
        width: 100vw
        img
            width: 100%

    .formular
        background-color: var(--main-color)
        margin-top: -1%
        display: flex
        box-sizing: border-box
        flex-direction: column
        align-items: center
        justify-content: center
        padding: 5vh 20vw

        h1
            margin-top: 0
            color: var(--secondary-color)

        .chestii
            max-width: clamp(750px, 50vw, 900px)


        .chestiicontain
            display: flex
            justify-content: space-between

            input
                background-color: #fff
                border: 2px solid var(--secondary-color)
                width: 300px
                padding: 15px 30px
                margin: 10px 10px
                font-size: 16px

                .button
                    margin-top: 4vh
            .error
                color: red
                font-size: 15px
                margin-bottom: 1vh
                text-align: center
                font-weight: bold

.footer
    background-color: var(--secondary-color)
    display: flex
    height: 8.5vh
    padding: 2vh 10vw
    box-sizing: border-box
    align-items: center
    justify-content: space-between  
    text-align: center
    a
        color: #fff
        text-decoration: none
        cursor: pointer
        font-size: clamp(15px, 0.1vw, 72px)
        transition: all 0.3s ease-in-out

        &:hover
            color: var(--main-color)
            text-decoration: underline
        &:active
            color: var(--main-color)
            text-decoration: underline


            /* Customize the label (the container) */
.containerl
    display: block
    position: relative
    padding-left: 35px
    margin-bottom: 12px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    margin-top: 3vh
    font-weight: 400
    text-align: left
    align-self: flex-start
    color: var(--secondary-color)

/* Hide the browser's default checkbox */
.containerl input[type="checkbox"]
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

/* Create a custom checkbox */
.checkmark
    position: absolute
    left: 0
    height: 18px
    width: 18px
    background-color: var(--main-color)
    border: solid 1px var(--secondary-color)
    border-radius: 5px

/* On mouse-over, add a grey background color */
.containerl:hover input ~ .checkmark
    background-color: rgba(0, 0, 0, 0)
    border: solid 1px var(--secondary-color)

/* When the checkbox is checked, add a blue background */
.containerl input:checked ~ .checkmark
    background-color: rgba(0, 0, 0, 0)
    border: solid 2px var(--secondary-color)

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after
    content: ""
    position: absolute
    display: none

/* Show the checkmark when checked */
.containerl input:checked ~ .checkmark:after
  display: block

/* Style the checkmark/indicator */
.containerl .checkmark:after
    left: 6px
    top: 2px
    width: 5px
    height: 10px
    border: solid var(--secondary-color)
    border-width: 0 3px 3px 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)

@media (max-width: 900px)
    .main-banner
        .header
            padding: 2vh 2vw
            height: 8vh
            .logo
                width: 30%
                img
                    width: 40%
            .nav
                width: clamp(100px, 40vw, 200px)
                .texts
                    font-weight: 700

                .button
                    font-weight: 700
                    padding: 10px 15px
        .push
            height: 8vh
        .formular
            padding: 5vh 5vw
            flex-direction: column

            .checkmark
                top: 35%

            .chestii
                width: 90vw

            .chestiicontain
                flex-direction: column
                align-items: center

                input
                    width: 250px
                
                .asds
                    width: 310px !important

        .footer
            flex-direction: column
            height: auto
            align-items: center
            justify-content: space-between
</style>
