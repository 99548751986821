import { render, staticRenderFns } from "./agegate.vue?vue&type=template&id=78f065f0&scoped=true&"
import script from "./agegate.vue?vue&type=script&lang=js&"
export * from "./agegate.vue?vue&type=script&lang=js&"
import style0 from "./agegate.vue?vue&type=style&index=0&id=78f065f0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f065f0",
  null
  
)

export default component.exports