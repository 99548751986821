import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auchan from '../views/Auchan.vue'
import Carrefour from '../views/Carrefour.vue'
import Kaufland from '../views/Kaufland.vue'
import Mega from '../views/Mega.vue'
import Penny from '../views/Penny.vue'
import AgeGate from '@/views/AgeGate.vue'
import Termeni from'@/views/Termeni'
import Politica from '@/views/Politica'
import Cookies from '@/views/Cookies'
import Alegere from '@/views/Alegere'
import Verificare from '@/views/Verificare'
import Proximitate from '@/views/Proximitate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Alegere',
    component: Alegere
  },
  {
    path: '/cora',
    name: 'Home',
    component: Home
  },
  {
    path: '/auchan',
    name: 'Auchan',
    component: Auchan
  },
  {
    path: '/carrefour',
    name: 'Carrefour',
    component: Carrefour
  },
  {
    path: '/kaufland',
    name: 'Kaufland',
    component: Kaufland
  },
  {
    path: '/megaimage',
    name: 'Mega',
    component: Mega
  },
  {
    path: '/penny',
    name: 'Penny',
    component: Penny
  },
  {
    path: '/age',
    name: 'AgeGate',
    component: AgeGate
  },
  {
    path: '/termeni-si-conditii',
    name: 'Termeni',
    component: Termeni
  },
  {
    path: '/politica-date-personale',
    name: 'Politica',
    component: Politica
  },
  {
    path: '/politica-de-cookie-uri',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/verificare',
    name: 'Verificare',
    component: Verificare
  },
  {
    path: '/proximitate',
    name: 'Proximitate',
    component: Proximitate
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
