gi<template>
  <div id="app">
    <age-gate />
    <router-view />
  </div>
</template>

<script>
import agegate from "@/components/agegate";

export default {
  components: {
    "age-gate": agegate,
  },
  mounted() {
    this.$cookiebot.consentBanner();
  },
};
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap')
.homebutton
  width: 100px
  height: 100px
  position: absolute
  bottom: 15vh
  left: 2vw
  img
    width: 100px !important
.asds
  background-color: #fff
  border: 0
  width: 365px !important
  padding: 15px 30px
  margin: 4vh auto
  display: block
  font-size: 16px
  border-radius:10px !important

.push
  height: 9vh
.header
  background-color: #05B9E3 !important
  display: flex
  height: 9vh
  padding: 2vh 2vw
  box-sizing: border-box
  align-items: center
  justify-content: space-between
  width: 100vw
  box-shadow: 0px 1px 10px rgb(0,0,0)
  text-align: center
  position: fixed

  .logo
    width: 20%

    img
      margin-top: 7.5vh
      height: 15vh

  .nav
    display: flex
    justify-content: flex-end
    align-items: center
    width: 40%

    .button
      margin: 2vw
      font-size: clamp(12px, 0.65vw, 72px)

      &:hover
        color: #fff
    .texts
      color: #fff
      text-decoration: none
      cursor: pointer
      text-transform: uppercase
      font-size: clamp(10px, 0.65vw, 72px)
      font-weight: bold

      &:hover
        color: var(--accent-color)

body, html
  margin: 0
  font-family: 'Open Sans', sans-serif
  overflow-x: hidden
  --main-color: #fff
  --secondary-color: #1873A3
  --accent-color: #1589B6

.button
  padding: 15px 25px
  background-color: #F5EC47 !important
  color: #05B9E3 !important
  font-weight: 800
  display: inline-block
  text-decoration: none
  border-radius: 7px
  transition: all 0.2s ease-in-out
  cursor: pointer
  margin: 0 5vw
  text-transform: uppercase
  &:active, &:hover
    background-color: var(--accent-color)
    color: var(--main-color)

.mobile
  display: none
@media (min-width: 1920px)
  .homebutton
    display: none
@media (max-width: 1000px)
  .header
    .logo
      img
        height: 7.5vh
        margin: 0
  .mobile
    display: inline

  .desktop
    display: none !important
</style>
