<template>
<div>
        <div class="header">
            <div class="nav">
                <router-link to="/" href="#" >Acasa</router-link>
            </div>
        </div>
        <div class="push"></div>
        <div ref="consent" class="fundal">
    </div>
</div>

</template>

<script>
export default {
    mounted() {
         this.$cookiebot.consentPage({
          async: true,
          ref: this.$refs.consent // Must be a Vue ref or html element
        })       
    },
}
</script>

<style lang="sass" scoped>
    .push
        height: 7vh
    .header
        background-color: var(--accent-color)
        display: flex
        height: 7vh
        padding: 2vh 10vw
        box-sizing: border-box
        align-items: center
        justify-content: center
        width: 100vw
        box-shadow: 0px 1px 10px rgb(0,0,0)
        text-align: center
        position: fixed

        .nav
            display: flex
            justify-content: center
            width: 60%
            a
                color: var(--main-color)
                text-decoration: none
                cursor: pointer
                text-transform: uppercase
                font-size: clamp(15px, 0.1vw, 72px)

                &:hover
                    color: var(--main-color)
        
    .fundal
        padding: 5vh 10vw
        color: var(--secondary-color)
        background-color: var(--main-color)
</style>